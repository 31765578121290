body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-align-right {
  text-align: right!important;
}

.button-min-width {
  margin-left: 20px!important;
  min-width: 120px!important;
}

.mb-0 {
  margin-bottom: 0px!important;
}

.mt-40 {
  margin-top: 40px!important;
}

.dollar {
  font-weight: bold;
}

.PrivateSwitchBase-root-21, .jss21  {
  padding: 3px!important;
}

.react-toast-notifications__container {
  padding-top: 70px!important;
}

h3 {
  margin-top: 0px!important;
}

.green-bold {
  color: green;
  font-weight: bold;
}

.bold {
  font-weight: bold;
}

.header-color {
  color: #3f51b5;
  font-weight: bold!important;
}

.inline-block-lm {
  display: inline-block;
  margin-left: 32px!important;
}

.inline-block {
  display: inline-block;
}

.buy-sell-wallet {
  width: 64px;
}